import styled from "styled-components";

import { palette } from "../../styles/util/variables";

const StyledParagraph: any = styled.p`
    font-size: 1.6rem;
    color: ${palette.grey};
    line-height: 2;
`;

export default StyledParagraph;
