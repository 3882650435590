import styled from "styled-components";

import { palette } from "../../styles/util/variables";

const StyledInput = styled.input`
    width: 35rem;
    height: 5rem;
    border-radius: 2.5rem;
    border: 1px solid ${palette.greyLight};
    font-size: 1.6rem;
    padding: 0 2rem;

    @media only screen and (max-width: 600px) {
        width: auto;
    }

    &::placeholder {
        color: ${palette.greyLight};
    }

    &:focus {
        outline: none;
        border: 2px solid ${palette.primary};
    }
`;

export default StyledInput;
