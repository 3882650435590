import React from "react";
import { withRouter } from "react-router-dom";

import StyledError from "./Error.styled";
import Layout from "../../UI/Layout/Layout";
import Heading from "../../UI/Heading/Heading.styled";
import Paragraph from "../../UI/Paragraph/Paragraph.styled";
import ActionButton from "../../components/ActionButton/ActionButton.styled";

import errorSVG from "../../assets/images/error.svg";

const Error = (props: any) => {
    return (
        <StyledError>
            <Layout>
                <div className="Error__container">
                    <div className="Error__padding">
                        <img
                            src={errorSVG}
                            alt="Error"
                            className="Error__img"
                        />
                    </div>
                    <div className="Error__padding">
                        <Heading className="Error__heading">
                            ¡Ha ocurrido un error!
                        </Heading>
                    </div>
                    <div className="Error__padding">
                        <Paragraph className="Error__p">
                            Intenta llenando tus datos nuevamente para completar
                            el proceso.
                        </Paragraph>
                    </div>
                    <ActionButton
                        onClick={() => props.history.push("/survey/complete")}
                    >
                        Volver
                    </ActionButton>
                </div>
            </Layout>
        </StyledError>
    );
};

Error.defaultProps = {};

export default withRouter(Error);
