import React from "react";

import { ReactComponent as ArrowLeftSVG } from "../../assets/images/arrow-left.svg";
import StyledGoBack from "./GoBack.styled";

const GoBack = (props: any) => {
    return (
        <StyledGoBack>
            <ArrowLeftSVG className="GoBack__arrow" />
        </StyledGoBack>
    );
};

GoBack.defaultProps = {};

export default GoBack;
