import styled from "styled-components";

import { palette } from "../../styles/util/variables";

const StyledProgressBar: any = styled.div`
    width: 100%;
    height: 0.8rem;
    background-color: ${palette.greyLight2};
    position: relative;
    border-radius: 0.8rem;

    .ProgressBar {
        &__fill {
            transition: all 1s;
            width: ${(props: any) => props.percentage}%;
            height: 100%;
            background-color: ${palette.primary};
            border-radius: 0.8rem;
        }
    }
`;

export default StyledProgressBar;
