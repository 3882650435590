import styled from "styled-components";

const StyledGoBack = styled.div`
    .GoBack {
        &__arrow {
            @media only screen and (max-width: 1100px) {
                width: 2rem;
                height: 2rem;
            }

            @media only screen and (max-width: 400px) {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
`;

export default StyledGoBack;
