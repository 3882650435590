import styled from "styled-components";
import ReactSelect from "react-select";

import { palette } from "../../styles/util/variables";

const StyledSelect: any = styled(ReactSelect)`
    .Select {
        &__control {
            width: 35rem;
            height: 5rem;
            border-radius: 2.5rem;
            border: 1px solid ${palette.greyLight};
            font-size: 1.6rem;
            padding: 0 2rem;
            display: flex;

            @media only screen and (max-width: 400px) {
                width: 100%;
                height: 4rem;
            }
        }

        &__menu {
            width: 35rem;
            position: absolute;
            z-index: 2;
            background-color: ${palette.white};
        }
    }
`;

export default StyledSelect;
