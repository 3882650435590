import React from "react";
import { withRouter } from "react-router-dom";

import StyledLayout from "./Layout.styled";
import StyledBrand from "../../components/Brand/Brand.styled";
import StyledBrand2 from "../../components/Brand2/Brand2.styled";
import ProgressBar from "../ProgressBar/ProgressBar";
import BrandSVGWhite from "../../assets/images/brand-white.svg";

const Layout = (props: any) => {
    return (
        <StyledLayout>
            <header className="Layout__header">
                <div className="Layout__header--top">
                    <div
                        className="Layout__brand"
                        onClick={() => props.history.push("/")}
                    >
                        <StyledBrand />
                    </div>
                    {typeof props.percentage === "number" ? (
                        <div className="Layout__progress-bar Layout__progress-bar--top">
                            <ProgressBar percentage={props.percentage} />
                            <p className="Layout__progress-bar__percentage">
                                {props.percentage}%
                            </p>
                        </div>
                    ) : null}
                    {/* link magazine*/}
                    <a
                        href="http://revista.datta.com.ec/publication/e6972818/mobile/?p=47"
                        target="__blank"
                        rel="noopener noreferrer"
                        className="Layout__link"
                    >
                        <div className="Layout__brand">
                            <StyledBrand2 />
                        </div>
                    </a>
                </div>
                <div className="Layout__header--bottom">
                    {typeof props.percentage === "number" ? (
                        <div className="Layout__progress-bar Layout__progress-bar--bottom">
                            <ProgressBar percentage={props.percentage} />
                            <p className="Layout__progress-bar__percentage">
                                {props.percentage}%
                            </p>
                        </div>
                    ) : null}
                </div>
            </header>
            <main className="Layout__main">{props.children}</main>
            <footer className="Layout__footer">
                {!props.home ? (
                    <div className={"Layout__footer__decoration"}>
                        <a
                            href="mailto:abc@example.com?subject = Feedback&body = Estimado Trade S.A.\n\n"
                            className="Layout__footer__decoration__link Layout__footer__decoration__link--email"
                        >
                            <p className="Layout__footer__decoration__email">
                                hola@trade.ec
                            </p>
                        </a>

                        <a
                            href="https://www.trade.ec"
                            target="__blank"
                            className="Layout__footer__decoration__link Layout__footer__decoration__link--brand"
                        >
                            <p className="Layout__footer__decoration__brand">
                                <span className="Layout__footer__decoration__brand__intro">
                                    Powered by
                                </span>{" "}
                                <img
                                    src={BrandSVGWhite}
                                    alt="Trade Logo"
                                    className="Layout__footer__decoration__brand__img"
                                />
                            </p>
                        </a>
                    </div>
                ) : null}
            </footer>
        </StyledLayout>
    );
};

Layout.defaultProps = {
    home: false
};

export default withRouter(Layout);
