import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import globalReducer from "./global/global.reducer.ts";

// Allow redux dev tools to work for this app
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// combine reducers into a single one
const reducer = combineReducers({
  // General UI state
  GLOBAL: globalReducer
});

const middlewares = [thunk];

// Create the general redux system + apply middleware if needed
const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
