import styled from "styled-components";

import { palette } from "../../../styles/util/variables";

const StyledFormPrimary: any = styled.form`
    display: grid;
    grid-template-columns: repeat(auto-fit, 35rem);
    grid-gap: 0 1rem;

    @media only screen and (max-width: 420px) {
        grid-template-columns: 1fr;
        grid-gap: 0 0.5rem;
    }

    .FormPrimary {
        &__input {
            margin-bottom: 1.5rem;

            @media only screen and (max-width: 420px) {
                height: 4rem;
            }
        }

        &__error {
            color: ${palette.error};
            font-size: 1.1rem;
            padding: 0 1.5rem;
            grid-column: 1 / -1;
        }

        &__btn {
            padding-top: 2rem;
        }
    }
`;

export default StyledFormPrimary;
