import styled from "styled-components";

import { sizes, palette } from "../../styles/util/variables";

const StyledLayout = styled.div`
    min-width: ${sizes.page.minWidth};
    max-width: ${sizes.page.maxWidth};
    min-height: ${sizes.page.minHeight};
    max-height: ${sizes.page.maxHeight};
    /* overflow: hidden; */
    position: relative;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 780px) {
        max-height: none;
    }

    .Layout {
        &__header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;

            &--top {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &--bottom {
                padding: 0 3rem;
                width: 100%;

                @media only screen and (max-width: 400px) {
                    padding: 0rem 2rem;
                }
            }
        }

        &__brand {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem 4rem;

            @media only screen and (max-width: 820px) {
                padding: 1rem 3rem;
            }

            @media only screen and (max-width: 400px) {
                padding: 1rem 2rem;
            }
        }

        &__progress-bar {
            flex: 1;
            display: flex;
            align-items: center;

            &--top {
                @media only screen and (max-width: 500px) {
                    display: none;
                }
            }

            &--bottom {
                display: none;
                @media only screen and (max-width: 500px) {
                    display: flex;
                }
            }

            &__percentage {
                padding: 0 1rem;
                font-size: 1.4rem;
                color: rgba(0, 0, 0, 0.45);
                font-weight: 500;
            }
        }

        &__main {
            flex: 1;
        }

        &__footer {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            bottom: 0;
            z-index: 2;

            @media only screen and (max-width: 780px) {
                position: relative;
            }

            &__decoration {
                height: 12rem;
                width: 51rem;
                background-color: ${palette.primary};
                padding: 3.5rem 6rem;
                text-align: right;
                color: ${palette.white};

                @media only screen and (max-width: 1000px) {
                    display: none;
                }

                @media only screen and (max-width: 780px) {
                    display: block;
                    width: 100%;
                }

                @media only screen and (max-width: 500px) {
                    height: 8rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-align: left;
                    font-size: 1.4rem;
                    padding: 3.5rem 2.5rem;
                }

                &__email {
                    padding-bottom: 1.5rem;
                    @media only screen and (max-width: 500px) {
                        padding-bottom: 0;
                    }
                }

                &__link {
                    text-decoration: none;
                    color: inherit;

                    &--email {
                        order: 2;
                    }

                    &--brand {
                        order: 1;
                    }
                }

                &__brand {
                    transition: all 0.2s;

                    @media only screen and (max-width: 500px) {
                        display: flex;
                        flex-direction: column;
                    }

                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }
                    &__intro {
                        font-weight: 300;

                        @media only screen and (max-width: 500px) {
                            padding-bottom: 0.5rem;
                        }
                    }

                    &__img {
                        width: 6rem;
                    }
                }
            }
        }
    }
`;

export default StyledLayout;
