import {
    UPDATE_FIELD,
    INIT_ANSWERS,
    SET_ANSWER,
    IS_COMPLETED
} from "./global.actions";

// Initial state
const initialState = {
    secondary: {
        jobTitle: "",
        businessType: "",
        country: ""
    },
    secondaryCompleted: false,
    primary: {
        firstName: "",
        lastName: "",
        email: "",
        company: ""
    },
    primaryCompleted: false,
    answers: []
};

const updateField = (state: any, action: any) => {
    const stateClone = { ...state };
    const form = { ...stateClone[action.payload.form] };
    form[action.payload.field] = action.payload.value;
    stateClone[action.payload.form] = form;
    const completed = isCompletedHandler(stateClone, action);
    stateClone[`${action.payload.form}Completed`] = completed;
    return stateClone;
};

const isCompletedHandler = (state: any, action: any) => {
    let completed = true;
    for (const field in state[action.payload.form]) {
        if (!state[action.payload.form][field]) {
            completed = false;
            break;
        }
    }
    return completed;
};

const isCompleted = (state: any, action: any) => {
    const stateClone = { ...state };
    const completed = isCompletedHandler(stateClone, action);
    stateClone[`${action.payload.form}Completed`] = completed;
    return stateClone;
};

const initAnswers = (state: any, action: any) => {
    const stateClone = { ...state };
    const answers: any[] = [];
    for (let i = 0; i < action.questionsLength; i++) {
        answers.push(null);
    }
    stateClone.answers = answers;
    return stateClone;
};

const setAnswer = (state: any, action: any) => {
    const stateClone = { ...state };
    const answersClone = [...stateClone.answers];
    answersClone[action.absoluteNumber] = action.question;
    stateClone.answers = answersClone;
    return stateClone;
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_FIELD:
            return updateField(state, action);
        case INIT_ANSWERS:
            return initAnswers(state, action);
        case SET_ANSWER:
            return setAnswer(state, action);
        case IS_COMPLETED:
            return isCompleted(state, action);
        default:
            return state;
    }
};

export default reducer;
