import React from "react";

import StyledSuccess from "./Success.styled";
import Layout from "../../UI/Layout/Layout";
import Heading from "../../UI/Heading/Heading.styled";
import Paragraph from "../../UI/Paragraph/Paragraph.styled";
import ActionButton from "../../components/ActionButton/ActionButton.styled";

import successSVG from "../../assets/images/success.svg";

const Success = (props: any) => {
    return (
        <StyledSuccess>
            <Layout>
                <div className="Success__container">
                    <div className="Success__padding">
                        <img
                            src={successSVG}
                            alt="Success"
                            className="Success__img"
                        />
                    </div>
                    <div className="Success__padding">
                        <Heading className="Success__heading">
                            ¡Tus resultados han sido <br /> enviados a tu
                            correo!
                        </Heading>
                    </div>
                    <div className="Success__padding">
                        <Paragraph className="Success__p">
                            ¡Tus resultados han sido enviados a tu correo!
                        </Paragraph>
                    </div>
                    <ActionButton
                        onClick={() => props.history.push("/survey/complete")}
                    >
                        Volver
                    </ActionButton>
                </div>
            </Layout>
        </StyledSuccess>
    );
};

Success.defaultProps = {};

export default Success;
