import styled from "styled-components";

import { palette } from "../../styles/util/variables";

const StyledHeading = styled.h1`
    color: ${palette.primary};
    font-size: 4.8rem;
    font-weight: 300;
`;

export default StyledHeading;
