import styled from "styled-components";

const Error: any = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .Error {
        &__container {
            width: 100%;
            padding: 2rem 18rem;
            padding-top: 8rem;

            @media only screen and (max-width: 780px) {
                padding: 3rem 10rem;
            }

            @media only screen and (max-width: 680px) {
                padding: 3rem;
            }
        }

        &__img {
            @media only screen and (max-width: 600px) {
                width: 5rem;
                height: 5rem;
            }
        }

        &__padding {
            padding-bottom: 2rem;
        }

        &__heading {
            @media only screen and (max-width: 600px) {
                font-size: 2.8rem;
            }

            @media only screen and (max-width: 400px) {
                font-size: 2.5rem;
            }
        }

        &__p {
            @media only screen and (max-width: 400px) {
                font-size: 1.4rem;
            }
        }
    }
`;

export default Error;
