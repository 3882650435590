import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import StyledApp from "./App.styled";
import Home from "../../pages/Home/Home";
import SurveyContainer from "../../pages/Survey/SurveryContainer";
import ResultsContainer from "../../pages/Results/ResultsContainer";
import ErrorPage from "../../pages/Error/Error";
import SuccessPage from "../../pages/Success/Success";

const App: React.FC = (props: any) => {
    return (
        <BrowserRouter>
            <StyledApp className="App">
                <Switch>
                    <Route
                        path="/survey/success"
                        exact
                        component={SuccessPage}
                    />
                    <Route path="/survey/error" exact component={ErrorPage} />
                    <Route
                        path="/survey/complete"
                        exact
                        component={ResultsContainer}
                    />
                    <Route
                        path="/survey/:question"
                        exact
                        component={SurveyContainer}
                    />
                    <Route path="/" component={Home} />
                </Switch>
            </StyledApp>
        </BrowserRouter>
    );
};

export default App;
