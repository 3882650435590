import React from "react";

import StyledHome from "./Home.styled";
import Layout from "../../UI/Layout/Layout";
import Heading from "../../UI/Heading/Heading.styled";
import Paragraph from "../../UI/Paragraph/Paragraph.styled";
import FormSecondaryContainer from "../../containers/Forms/FormSecondaryContainer";

import homeImg from "../../assets/images/home.png";
import StyledBrand2Slime from "../../components/Brand2Slime/Brand2Slime.styled";

const Home = (props: any) => {
    return (
        <StyledHome>
            <Layout home>
                <div className="Home__main">
                    <Heading className="Home__heading">
                        ¿Estás listo para lanzar tu eCommerce?
                    </Heading>
                    <Paragraph className="Home__p">
                        El siguiente test te ayudará a validar si estás cubierto
                        en todas las áreas para brindar una gran experiencia de
                        usuario y servicio para los clientes que usen tu
                        plataforma online. Así como detectar tus fortalezas y
                        debilidades que mantienes actualmente.
                    </Paragraph>
                    <a
                        href="http://revista.datta.com.ec/publication/e6972818/mobile/?p=47"
                        target="__blank"
                        rel="noopener noreferrer"
                        className="Home__link"
                    >
                        <Paragraph className="Home__p--2">
                            Revisa el especial de eCommerce de{" "}
                            <StyledBrand2Slime />
                        </Paragraph>
                    </a>

                    <FormSecondaryContainer />
                </div>
                <a
                    href="http://revista.datta.com.ec/publication/e6972818/mobile/?p=47"
                    target="__blank"
                    rel="noopener noreferrer"
                >
                    <aside className="Home__aside">
                        <img
                            src={homeImg}
                            alt="Home"
                            className="Home__aside__img"
                        />
                    </aside>
                </a>
            </Layout>
        </StyledHome>
    );
};

Home.defaultProps = {};

export default Home;
