import React from "react";

import StyledSurvey from "./Survey.styled";
import Layout from "../../UI/Layout/Layout";
import QuestionCounter from "../../components/QuestionCounter/QuestionCounter";
import QuestionButtons from "../../components/QuestionButtons/QuestionButtons";

const Survey = (props: any) => {
    // answers={answers}
    // setAnswers={setAnswers}
    return (
        <StyledSurvey>
            <Layout percentage={props.percentage}>
                <div className="Survey__container">
                    {/* QuestionCounter */}
                    <QuestionCounter
                        questionIndex={props.questionIndex}
                        questionsLength={props.questionsLength}
                    />
                    {/* CategoryHeading */}
                    <p className="Survey__category">
                        {props.question.category}
                    </p>
                    {/* Divider */}
                    <div className="Survey__divider" />
                    {/* <h2 className="Survey__heading">
                        Question {props.question.number + 1}
                    </h2> */}
                    <p className="Survey__paragraph">{props.question.text}</p>
                    <QuestionButtons
                        question={props.question}
                        questionIndex={props.questionIndex}
                        questionsLength={props.questionsLength}
                    />
                </div>
            </Layout>
        </StyledSurvey>
    );
};

Survey.defaultProps = {};

export default Survey;
