import styled from "styled-components";

const StyledHome = styled.div`
    .Home {
        &__main {
            width: calc(100% - 45rem);
            height: 100%;
            padding: 0rem 10rem;
            position: relative;
            z-index: 4;

            @media only screen and (max-width: 1135px) {
                padding: 0rem 5rem;
                width: calc(100% - 38rem);
            }

            @media only screen and (max-width: 820px) {
                padding: 0rem 3rem;
            }

            @media only screen and (max-width: 780px) {
                width: 100%;
                height: auto;
            }
        }

        &__heading {
            @media only screen and (max-width: 965px) {
                font-size: 3.3rem;
            }

            @media only screen and (max-width: 400px) {
                font-size: 2.4rem;
            }
        }

        &__p {
            padding: 2rem 0;

            @media only screen and (max-width: 860px) {
                font-size: 1.4rem !important;
            }

            @media only screen and (max-width: 400px) {
                font-size: 1.2rem;
            }

            &--2 {
                margin-top: -1rem;
                padding-bottom: 2rem;
                font-weight: 700;
                display: flex;
                align-items: center;

                @media only screen and (max-width: 850px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                @media only screen and (max-width: 780px) {
                    flex-direction: row;
                    align-items: center;
                }

                @media only screen and (max-width: 430px) {
                    display: inline-block;
                }
            }
        }

        &__link {
            text-decoration: none;
        }

        &__aside {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            width: 38rem;
            height: 100vh;

            @media only screen and (max-width: 780px) {
                display: none;
            }

            &__img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
`;

export default StyledHome;
