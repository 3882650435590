import styled from "styled-components";

import { ReactComponent as BrandSVG } from "../../assets/images/brand2.svg";

const StyledBrand = styled(BrandSVG)`
    width: 10rem;
    height: 10rem;
    cursor: pointer;

    @media only screen and (max-width: 500px) {
        width: 8rem;
        height: 8rem;
    }
`;

export default StyledBrand;
