import React from "react";

import StyledFormPrimary from "./FormPrimary.styled";
import ActionButton from "../../ActionButton/ActionButton.styled";
import Input from "../../Input/Input.styled";

const FormPrimary = (props: any) => {
    const changeHandler = (e: any, field: string) => {
        const value = e.target.value;
        props.updateField({
            form: "primary",
            field,
            value
        });
    };
    return (
        <StyledFormPrimary
            noValidate
            onSubmit={(e: any) => props.onSubmit(e)}
            className="Form FormPrimary"
        >
            <Input
                type="text"
                autoCapitalize="words"
                placeholder="Nombre"
                className="FormPrimary__input"
                onChange={e => changeHandler(e, "firstName")}
                value={props.primary.firstName}
                max="50"
            />
            <Input
                type="text"
                autoCapitalize="words"
                placeholder="Apellido"
                className="FormPrimary__input"
                onChange={e => changeHandler(e, "lastName")}
                value={props.primary.lastName}
                max="50"
            />
            <Input
                type="email"
                placeholder="Correo Electrónico"
                className="FormPrimary__input"
                onChange={e => changeHandler(e, "email")}
                value={props.primary.email}
                max="100"
            />
            <Input
                type="text"
                autoCapitalize="words"
                placeholder="Empresa / Negocio"
                className="FormPrimary__input"
                onChange={e => changeHandler(e, "company")}
                value={props.primary.company}
                max="50"
            />
            <p className="FormPrimary__error">{props.error}</p>
            <div className="FormPrimary__btn">
                <ActionButton>Enviar al correo</ActionButton>
            </div>
        </StyledFormPrimary>
    );
};

export default FormPrimary;
