import React from "react";

import StyledResults from "./Results.styled";
import Layout from "../../UI/Layout/Layout";
import Heading from "../../UI/Heading/Heading.styled";
import Paragraph from "../../UI/Paragraph/Paragraph.styled";
import FormPrimaryContainer from "../../containers/Forms/FormPrimaryContainer";

import resultsImg from "../../assets/images/results.jpg";

const Results = (props: any) => {
    return (
        <StyledResults>
            <Layout>
                <div className="Results__main">
                    <Heading className="Results__heading">
                        Descarga un PDF con los resultados de tu test
                    </Heading>
                    <div className="Results__p">
                        <Paragraph>
                            Completa los datos y recibe un análisis de los
                            resultados.
                        </Paragraph>
                    </div>
                    <FormPrimaryContainer />
                </div>
                <aside className="Results__aside">
                    <img
                        src={resultsImg}
                        alt="Results"
                        className="Results__aside__img"
                    />
                </aside>
            </Layout>
        </StyledResults>
    );
};

Results.defaultProps = {};

export default Results;
