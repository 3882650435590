import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import FormSecondary from "../../components/Forms/FormSecondary/FormSecondary";

const FormSecondaryContainer = (props: any) => {
    const [error, setError]: any = useState(false);

    const submitHandler = (e: any) => {
        e.preventDefault();
        if (props.secondaryCompleted) {
            props.history.push("/survey/1");
        } else {
            setError(true);
        }
    };

    return (
        <FormSecondary
            onSubmit={submitHandler}
            clear={() => setError(false)}
            error={error}
        />
    );
};

FormSecondaryContainer.defaultProps = {};

const mapStateToProps = (state: any) => {
    return {
        secondaryCompleted: state.GLOBAL.secondaryCompleted
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FormSecondaryContainer));
