import React from "react";

import StyledProgressBar from "./ProgressBar.styled";

const ProgressBar = (props: any) => {
    return (
        <StyledProgressBar percentage={props.percentage}>
            <div className="ProgressBar__fill" />
        </StyledProgressBar>
    );
};

ProgressBar.defaultProps = {};

export default ProgressBar;
