import styled from "styled-components";

import { ReactComponent as BrandSVG } from "../../assets/images/brand2slime.svg";

const StyledBrand = styled(BrandSVG)`
    padding-left: 1rem;
    cursor: pointer;

    @media only screen and (max-width: 850px) {
        padding-left: 0;
    }

    @media only screen and (max-width: 780px) {
        padding-left: 1rem;
    }

    @media only screen and (max-width: 430px) {
        padding-left: 0;
    }
`;

export default StyledBrand;
