import styled from "styled-components";

import { palette } from "../../styles/util/variables";

const StyledSurvey = styled.div`
    .Survey {
        &__container {
            padding: 2rem 18rem;

            @media only screen and (max-width: 1100px) {
                padding: 2rem 3rem;
            }

            @media only screen and (max-width: 400px) {
                padding: 2rem;
            }
        }

        &__category {
            font-size: 3.6rem;
            color: ${palette.primary};
            padding: 1.5rem 0;
            user-select: none;

            @media only screen and (max-width: 400px) {
                font-size: 2.2rem;
            }
        }

        &__divider {
            width: 60%;
            height: 0.2rem;
            background-color: ${palette.greyLight2};
        }

        &__heading {
            font-size: 4.8rem;
            color: ${palette.primary};
            padding-top: 4rem;
            padding-bottom: 2rem;
            font-weight: 300;
        }

        &__paragraph {
            color: ${palette.greyLight5};
            font-size: 1.6rem;
            font-weight: 300;
            padding-top: 2rem;
            line-height: 1.5;
        }
    }
`;

export default StyledSurvey;
