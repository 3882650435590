import styled from "styled-components";
import { palette } from "../../styles/util/variables";

const StyledActionButton = styled.button`
    border-radius: 2.5rem;
    background-color: ${palette.primary};
    color: ${palette.white};
    padding: 1.5rem 2em;
    font-size: 1.6rem;
    cursor: pointer;
    transition: all 0.2s;
    border: none;

    &:hover {
        box-shadow: 2px 2px 0.4px rgba(0, 0, 0, 0.2);
    }

    &:focus {
        outline: none;
        border: 2px solid blue;
    }

    @media only screen and (max-width: 400px) {
        width: 100%;
    }
`;

export default StyledActionButton;
