// Project constants

const CONSTANTS = {
    // Commerce Name
    CLIENT_NAME: "Trade S.A.",
    // Default max waiting time for a request reply
    DEFAULT_REQUEST_TIMEOUT: 30000
};

export default CONSTANTS;
