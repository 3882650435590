import styled from "styled-components";

import { palette } from "../../styles/util/variables";

const StyledQuestionCounter: any = styled.div`
    display: flex;
    align-items: center;
    transform: ${(props: any) =>
        props.questionIndex >= 2 ? "translateX(-4.8rem)" : "translateX(-2rem)"};
    height: 2.8rem;
    cursor: pointer;

    @media only screen and (max-width: 1100px) {
        transform: ${(props: any) =>
            props.questionIndex >= 2
                ? "translateX(0rem)"
                : "translateX(-2rem)"};
    }

    @media only screen and (max-width: 400px) {
        transform: ${(props: any) =>
            props.questionIndex >= 2
                ? "translateX(0rem)"
                : "translateX(-1rem)"};
    }

    .QuestionCounter {
        &__count {
            padding-left: 2rem;
            font-size: 1.8rem;
            color: ${palette.greyLight3};
            user-select: none;

            @media only screen and (max-width: 400px) {
                font-size: 1.4rem;
                padding-left: 1rem;
            }
        }
    }
`;

export default StyledQuestionCounter;
