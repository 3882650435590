import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import v from "voca";

import FormPrimary from "../../components/Forms/FormPrimary/FormPrimary";

import { UPDATE_FIELD } from "../../redux/global/global.actions";
import axios from "../../util/axios";

const FormPrimaryContainer = (props: any) => {
    const [error, setError]: any = useState("");

    const saveResults = async () => {
        // Sanitize data with voca
        const primary = { ...props.primary };
        for (const field in primary) {
            if (field === "email") {
                continue;
            }
            primary[field] = v.capitalize(primary[field]);
        }
        const data = {
            ...primary,
            ...props.secondary,
            answers: props.answers
        };
        //Send request to save data, email admin, send pdf to client
        try {
            await axios.post("/survey/publish", data);
            props.history.push("/survey/success");
        } catch (e) {
            props.history.push("/survey/error");
        }
    };

    const submitHandler = (e: any) => {
        e.preventDefault();
        setError("");
        const isEmailValid = isEmail(props.primary.email);
        if (!props.primaryCompleted) {
            setError("Debe llenar todos los campos para continuar");
        } else if (!isEmailValid) {
            setError("Correo Electrónico inválido, verifíquelo por favor");
        } else {
            saveResults();
        }
    };

    return (
        <FormPrimary
            onSubmit={(e: any) => submitHandler(e)}
            error={error}
            primary={props.primary}
            updateField={props.updateField}
        />
    );
};

FormPrimaryContainer.defaultProps = {};

const mapStateToProps = (state: any) => {
    return {
        primaryCompleted: state.GLOBAL.primaryCompleted,
        primary: state.GLOBAL.primary,
        secondary: state.GLOBAL.secondary,
        answers: state.GLOBAL.answers
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateField: (payload: any) => dispatch({ type: UPDATE_FIELD, payload })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FormPrimaryContainer));
