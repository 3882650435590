const palette = {
    primary: "#FF6565",
    grey: "#3D3D3D",
    greyLight: "#d9d9d9",
    greyLight2: "#f5f5f5",
    greyLight3: "#686868",
    greyLight4: "#b2b2b2",
    greyLight5: "#3D3D3D",
    black: "#000",
    white: "#fff",

    error: "#eb5b52"
};

const fonts = {
    primary: "'Red Hat Text', sans-serif"
};

const sizes = {
    page: {
        minWidth: "100vw",
        maxWidth: "100vw",
        minHeight: "100vh",
        maxHeight: "100vh"
    }
};

export { palette, fonts, sizes };
