import { css } from "styled-components";

const typography = css`
    html {
        /* 16px default to 10px */
        font-size: 62.5%;
    }

    body {
        font-family: "Lexend Tera", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.6rem;
    }
`;

export default typography;
