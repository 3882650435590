import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import StyledFormSecondary from "./FormSecondary.styled";
import ActionButton from "../../ActionButton/ActionButton.styled";
import Select from "../../Select/Select";

const FormSecondary = (props: any) => {
    const jobTitleOptions = [
        {
            label: "MD, CEO o Propietario",
            value: "MD, CEO o Propietario",
            field: "jobTitle",
            form: "secondary"
        },
        {
            label: "Gerente/Director eCommerce",
            value: "Gerente/Director eCommerce",
            field: "jobTitle",
            form: "secondary"
        },
        {
            label: "Tech Lead",
            value: "Tech Lead",
            field: "jobTitle",
            form: "secondary"
        },
        {
            label: "Representante Comercial",
            value: "Representante Comercial",
            field: "jobTitle",
            form: "secondary"
        },
        { label: "Otro", value: "Otro", field: "jobTitle", form: "secondary" }
    ];

    const businessTypeOptions = [
        {
            label: "Producto",
            value: "Producto",
            field: "businessType",
            form: "secondary"
        },
        {
            label: "Servicio",
            value: "Servicio",
            field: "businessType",
            form: "secondary"
        }
    ];

    const countryOptions = [
        {
            label: "Ecuador",
            value: "Ecuador",
            field: "country",
            form: "secondary"
        },
        {
            label: "Colombia",
            value: "Colombia",
            field: "country",
            form: "secondary"
        },
        {
            label: "México",
            value: "México",
            field: "country",
            form: "secondary"
        },
        {
            label: "Republica Dominicana",
            value: "Republica Dominicana",
            field: "country",
            form: "secondary"
        }
    ];

    return (
        <StyledFormSecondary
            noValidate
            onSubmit={props.onSubmit}
            className="Form"
        >
            <div className="FormSecondary__input">
                <Select
                    placeholder="Cargo"
                    options={jobTitleOptions}
                    clear={props.clear}
                    currentValue={props.secondary.jobTitle}
                />
            </div>
            <div className="FormSecondary__input">
                <Select
                    placeholder="Tipo de Empresa"
                    options={businessTypeOptions}
                    clear={props.clear}
                    currentValue={props.secondary.businessType}
                />
            </div>
            <div className="FormSecondary__input">
                <Select
                    placeholder="País"
                    options={countryOptions}
                    clear={props.clear}
                    currentValue={props.secondary.country}
                />
            </div>
            {props.error ? (
                <p className="FormSecondary__error">
                    Debe llenar todos los campos para continuar
                </p>
            ) : null}
            <div className="FormSecondary__btn">
                <ActionButton>Realizar el test</ActionButton>
            </div>
        </StyledFormSecondary>
    );
};

const mapStateToProps = (state: any) => {
    return {
        secondary: state.GLOBAL.secondary
    };
};

export default connect(mapStateToProps)(withRouter(FormSecondary));
