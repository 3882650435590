import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import StyledQuestionCounter from "./QuestionCounter.styled";
import GoBack from "../GoBack/GoBack";

import { SET_ANSWER } from "../../redux/global/global.actions";

const QuestionCounter = (props: any) => {
    const backHandler = () => {
        props.setAnswer(null, props.questionIndex - 1);
        props.history.push(`/survey/${props.questionIndex - 1}`);
    };
    return (
        <StyledQuestionCounter
            questionIndex={props.questionIndex}
            onClick={() => backHandler()}
        >
            {props.questionIndex >= 2 ? <GoBack /> : null}
            <p className="QuestionCounter__count">
                Pregunta {props.questionIndex} / {props.questionsLength}
            </p>
        </StyledQuestionCounter>
    );
};

QuestionCounter.defaultProps = {};

const mapStateToProps = (state: any) => {
    return {
        answers: state.GLOBAL.answers
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setAnswer: (question: any, absoluteNumber: number) =>
            dispatch({ type: SET_ANSWER, question, absoluteNumber })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(QuestionCounter));
