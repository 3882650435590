import styled from "styled-components";

import { palette } from "../../../styles/util/variables";

const StyledFormSecondary = styled.form`
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 400px) {
        padding-top: 2rem;
    }

    .FormSecondary {
        &__input {
            margin-bottom: 1.5rem;
        }

        &__error {
            color: ${palette.error};
            font-size: 1.1rem;
            padding: 0 1.5rem;
        }

        &__btn {
            padding-top: 2rem;

            @media only screen and (max-width: 780px) {
                padding-bottom: 4rem;
            }
        }
    }
`;

export default StyledFormSecondary;
