import axios from "axios";

import CONSTANTS from "../config/constants";

const axiosDefault = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: CONSTANTS.DEFAULT_REQUEST_TIMEOUT,
    headers: {
        Platform: process.env.REACT_APP_PLATFORM
    }
});

export default axiosDefault;
