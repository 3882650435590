import styled from "styled-components";

const StyledResults = styled.div`
    .Results {
        &__main {
            width: calc(100% - 45rem);
            height: 100%;
            padding: 2rem 10rem;
            position: relative;
            z-index: 4;

            @media only screen and (max-width: 1200px) {
                padding: 2rem 4rem;
                padding-top: 0;
            }

            @media only screen and (max-width: 780px) {
                width: 100%;
                height: auto;
            }

            @media only screen and (max-width: 600px) {
                padding: 2rem 3rem;
            }

            @media only screen and (max-width: 400px) {
                padding: 2rem;
            }
        }

        &__heading {
            @media only screen and (max-width: 1250px) {
                font-size: 3.5rem;
            }

            @media only screen and (max-width: 910px) {
                font-size: 2.6rem;
            }
        }

        &__p {
            padding: 2rem 0;
        }

        &__aside {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 4;
            width: 38rem;
            height: 100vh;

            @media only screen and (max-width: 780px) {
                display: none;
            }

            &__img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
`;

export default StyledResults;
