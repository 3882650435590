import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Survey from "./Survery";

import productQuestions from "../../util/questions-product.json";
import serviceQuestions from "../../util/questions-service.json";
import { INIT_ANSWERS } from "../../redux/global/global.actions";

const SurveyContainer = (props: any) => {
    const [questions, setQuestions]: any = useState([]);
    const [question, setQuestion]: any = useState(null);
    const [percentage, setPercentage]: any = useState(30);
    const [questionsLength, setQuestionsLength]: any = useState(0);

    // Sets the questionary
    useEffect(() => {
        // give the apropiate survey
        if (props.businessType === "Producto") {
            setQuestions(productQuestions.categories);
        } else {
            setQuestions(serviceQuestions.categories);
        }
        const total = getQuestionsLength();
        setQuestionsLength(total);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions]);

    // Init answers
    useEffect(() => {
        if (questionsLength > 0) {
            props.initAnswers(questionsLength);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionsLength]);

    // Selects the next question to display
    useEffect(() => {
        if (questionsLength < 1) {
            return;
        }
        const questionIndex = props.match.params.question - 1;
        // redirect to fill secondary form
        if (!props.secondaryCompleted) {
            props.history.replace("/");
            return;
        }
        if (questionsLength > 0 && !props.answers[questionIndex]) {
            // redirect to next question to answer first
            const next = props.answers.findIndex((ans: any) => !ans);
            props.history.replace(`/survey/${next + 1}`);
        }
        // set to question in param
        const selectedQuestion = getQuestion(questionIndex);
        setQuestion(selectedQuestion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionsLength, props.match.params.question]);

    // sets the completion state of the survey
    useEffect(() => {
        const completedQuestions = props.answers.reduce(
            (acc: number, next: any) => {
                if (next) {
                    return acc + 1;
                }
                return acc;
            },
            0
        );
        const newPercentage = Math.ceil(
            (completedQuestions / questionsLength) * 100
        );
        setPercentage(newPercentage);
    }, [props.answers, props.match.params.question, questionsLength]);

    // Returns an array with all questions combined
    const getRawQuestions = () => {
        const cleanedQuestions = questions.map((category: any) => {
            return category.questions;
        });
        return [].concat.apply([], cleanedQuestions);
    };

    // Returns the total number of questions
    const getQuestionsLength = () => {
        const mergedQuestions = getRawQuestions();
        return mergedQuestions.length;
    };

    // Returns the question we are looking for
    const getQuestion = (questionIndex: number) => {
        const mergedQuestions = getRawQuestions();
        return mergedQuestions[questionIndex];
    };

    return question ? (
        <Survey
            percentage={percentage}
            question={question}
            questionIndex={props.match.params.question}
            questionsLength={questionsLength}
        />
    ) : null;
};

SurveyContainer.defaultProps = {};

const mapStateToProps = (state: any) => {
    return {
        answers: state.GLOBAL.answers,
        secondaryCompleted: state.GLOBAL.secondaryCompleted,
        businessType: state.GLOBAL.secondary.businessType
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        initAnswers: (questionsLength: any) =>
            dispatch({ type: INIT_ANSWERS, questionsLength })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SurveyContainer));
