import React, { useEffect } from "react";
import { connect } from "react-redux";

import StyledSelect from "./Select.styled";

import { UPDATE_FIELD } from "../../redux/global/global.actions";

const Select = (props: any) => {
    useEffect(() => {}, [props.options]);

    const changeHandler = (e: any) => {
        props.clear();
        props.updateField(e);
    };

    return (
        <StyledSelect
            classNamePrefix="Select"
            value={props.options.filter(
                (option: any) => option.value === props.currentValue
            )}
            onChange={(e: any) => changeHandler(e)}
            options={props.options}
            isSearchable={false}
            {...props}
        />
    );
};

const mapStateToProps = (state: any) => {
    return {
        secondaryForm: state.GLOBAL.secondary
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateField: (payload: any) => dispatch({ type: UPDATE_FIELD, payload })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Select);
