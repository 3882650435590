import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import StyledQuestionButtons from "./QuestionButtons.styled";

import { SET_ANSWER } from "../../redux/global/global.actions";

const QuestionButtons = (props: any) => {
    const nextHandler = (index: number) => {
        props.setAnswer(
            {
                ...props.question,
                answer: index,
                absoluteNumber: props.questionIndex - 1
            },
            props.questionIndex - 1
        );
        if (parseInt(props.questionIndex) === props.questionsLength) {
            props.history.push(`/survey/complete`);
        } else {
            props.history.push(`/survey/${parseInt(props.questionIndex) + 1}`);
        }
    };
    const buttons = props.question.options.map((option: any, index: number) => {
        const answerObj = props.answers[props.questionIndex - 1];
        return (
            <div
                key={index}
                onClick={() => nextHandler(index)}
                className={`QuestionButtons__button ${
                    answerObj && answerObj.answer === index
                        ? "QuestionButtons__button--active"
                        : ""
                }`}
            >
                {option.text}
            </div>
        );
    });
    return (
        <StyledQuestionButtons question={props.question}>
            <p className="QuestionButtons__label QuestionButtons__label--min">
                {props.question.minText}
            </p>
            {buttons}
            <p className="QuestionButtons__label QuestionButtons__label--max">
                {props.question.maxText}
            </p>
        </StyledQuestionButtons>
    );
};

QuestionButtons.defaultProps = {};

const mapStateToProps = (state: any) => {
    return {
        answers: state.GLOBAL.answers
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setAnswer: (question: any, absoluteNumber: number) =>
            dispatch({ type: SET_ANSWER, question, absoluteNumber })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(QuestionButtons));
