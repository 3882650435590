import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Results from "./Results";

const ResultsContainer = (props: any) => {
    useEffect(() => {
        if (!props.secondaryCompleted) {
            props.history.replace("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Results />;
};

ResultsContainer.defaultProps = {};

const mapStateToProps = (state: any) => {
    return {
        secondaryCompleted: state.GLOBAL.secondaryCompleted
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ResultsContainer));
