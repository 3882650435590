import styled from "styled-components";

import { palette } from "../../styles/util/variables";

const StyledQuestionButtons: any = styled.div`
    display: grid;
    grid-template-columns:
        auto repeat(${(props: any) => props.question.options.length}, 1fr)
        auto;
    grid-template-rows: 6rem;
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    padding-top: 6rem;

    @media only screen and (max-width: 750px) {
        grid-template-rows:
            auto repeat(${(props: any) => props.question.options.length}, 1fr)
            auto;
        grid-template-columns: 1fr;
        justify-items: center;
        grid-gap: 1.5rem;
        padding-bottom: 3rem;
        padding-top: 4rem;
    }

    .QuestionButtons {
        &__button {
            height: 5.5rem;
            width: 100%;
            border: 1px solid ${palette.greyLight4};
            border-radius: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${palette.greyLight5};
            transition: all 0.2s;
            cursor: pointer;

            @media only screen and (max-width: 480px) {
                height: 5rem;
            }

            &:hover {
                border-color: ${palette.primary};
                color: ${palette.primary};
            }

            &:active,
            &--active {
                border: none;
                background-color: ${palette.primary};
                color: ${palette.white} !important;
                transform: scale(1.05);
            }
        }

        &__label {
            color: ${palette.primary};

            &--min {
            }

            &--max {
            }
        }
    }
`;

export default StyledQuestionButtons;
